<template>
  <v-container>
    <template v-if="canEdit">
      <DialogDetachedContracts
        v-if="model.hasDetachedContracts"
        :employeeId="employeeId"
        :customerId="customerId"
        @updatePage="updatePage"
      />
    </template>

    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Dados cadastrais</h5>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-text-field
            id="name"
            name="name"
            label="Nome"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.name"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="document"
            name="document"
            label="CPF"
            dense
            outlined
            required
            :rules="[required, cpf]"
            v-mask="'###.###.###-##'"
            v-model="model.document"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-autocomplete
            id="gender"
            name="gender"
            label="Sexo"
            dense
            outlined
            required
            :rules="[required]"
            :items="['Masculino', 'Feminino', 'Outro']"
            v-model="model.gender"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseMaritalStatus
            id="maritalStatus"
            name="maritalStatus"
            label="Estado civil"
            required
            :rules="[required]"
            v-model="model.maritalStatus"
          />
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="3"
          sm="6"
          v-if="
            model.maritalStatus === 'Casado(a)' ||
            model.maritalStatus === 'União estável'
          "
        >
          <BaseDatePicker
            id="eventDate"
            name="eventDate"
            :label="
              model.maritalStatus === 'União estável'
                ? 'Data do evento'
                : 'Data de casamento'
            "
            required
            :rules="[required]"
            v-model="model.eventDate"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseDatePicker
            id="birthDate"
            name="birthDate"
            label="Data de nascimento"
            required
            :rules="[required]"
            :disabled="model.situation === 'Ativo'"
            v-model="model.birthDate"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="nationalIdCard"
            name="nationalIdCard"
            label="RG"
            dense
            outlined
            :rules="checkNationalIdRequired ? [required] : []"
            v-model="model.nationalIdCard"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseDatePicker
            id="nationalIdCardDispatchDate"
            name="nationalIdCardDispatchDate"
            label="Data de expedição"
            :rules="checkNationalIdRequired ? [required] : []"
            v-model="model.nationalIdCardDispatchDate"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="nationalIdCardIssuer"
            name="nationalIdCardIssuer"
            label="Orgão emissor"
            dense
            outlined
            :rules="checkNationalIdRequired ? [required] : []"
            v-model="model.nationalIdCardIssuer"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-text-field
            id="motherName"
            name="motherName"
            label="Nome da mãe"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.motherName"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseDatePicker
            id="admissionDate"
            name="admissionDate"
            label="Data de admissão"
            required
            :rules="[required]"
            v-model="model.admissionDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-text-field
            id="position"
            name="position"
            label="Cargo"
            dense
            outlined
            v-model="model.position"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6">
          <v-text-field
            id="pis"
            name="pis"
            dense
            outlined
            label="PIS"
            v-mask="'###.#####.##-#'"
            v-model="model.pis"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseNumber
            id="susCardNumber"
            name="susCardNumber"
            label="Cartão SUS"
            maxlength="15"
            v-model="model.susCardNumber"
          />
        </v-col>
        <v-col
          cols="12"
          :xl="checkColumn ? 2 : 3"
          :lg="checkColumn ? 2 : 3"
          :md="checkColumn ? 2 : 3"
          sm="6"
        >
          <v-text-field
            id="registration"
            name="registration"
            label="Matrícula"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.registration"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <BaseMoney
            id="salary"
            name="salary"
            label="Salário"
            v-model="model.salary"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-autocomplete
            id="nationality"
            name="nationality"
            label="Nacionalidade"
            dense
            outlined
            :items="['Brasileiro', 'Estrangeiro']"
            v-model="model.nationality"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6" v-if="editMode">
          <BaseMovementSituation
            id="situation"
            name="situation"
            label="Status"
            required
            :rules="[required]"
            :disabled="model.situation !== 'Em análise na operadora'"
            :registeredStatus="registeredStatus"
            v-model="model.situation"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Vigências e carteirinhas do plano</h5>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="dentalEffectiveStartDate"
            name="dentalEffectiveStartDate"
            label="Vigência odonto"
            v-model="model.dentalEffectiveStartDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="dentalBillingDate"
            name="dentalBillingDate"
            label="Faturamento odonto"
            v-model="model.dentalBillingDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <v-text-field
            id="dentalPlanCardNumber"
            name="dentalPlanCardNumber"
            label="Carteirinha de odonto"
            dense
            outlined
            v-model="model.dentalPlanCardNumber"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="healthEffectiveStartDate"
            name="healthEffectiveStartDate"
            label="Vigência saúde"
            v-model="model.healthEffectiveStartDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="healthBillingDate"
            name="healthBillingDate"
            label="Faturamento saúde"
            v-model="model.healthBillingDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <v-text-field
            id="healthPlanCardNumber"
            name="healthPlanCardNumber"
            label="Carteirinha de saúde"
            dense
            outlined
            v-model="model.healthPlanCardNumber"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="lifeEffectiveStartDate"
            name="lifeEffectiveStartDate"
            label="Vigência seguro de vida"
            v-model="model.lifeEffectiveStartDate"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
          <BaseDatePicker
            id="lifeBillingDate"
            name="lifeBillingDate"
            label="Faturamento seguro de vida"
            v-model="model.lifeBillingDate"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Endereço</h5>
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="4">
          <BaseZipcode
            id="zipCode"
            name="zipCode"
            label="CEP"
            required
            :rules="[required, zipcode]"
            v-model="model.zipCode"
            @fill="fill($event)"
          />
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="8" sm="8">
          <v-text-field
            id="address"
            name="address"
            label="Endereço"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.address"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <v-text-field
            id="number"
            name="number"
            type="number"
            label="Número"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.number"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="5">
          <v-text-field
            id="complement"
            name="complement"
            label="Complemento"
            dense
            outlined
            v-model="model.complement"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="5">
          <v-text-field
            id="district"
            name="district"
            label="Bairro"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.district"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="4">
          <BaseStates
            id="state"
            name="state"
            label="Estado"
            required
            :rules="[required]"
            v-model="model.state"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="5">
          <v-text-field
            id="city"
            name="city"
            label="Cidade"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.city"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Contato</h5>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="phoneNumber"
            name="phoneNumber"
            label="DDD e Telefone"
            dense
            outlined
            v-model="model.phoneNumber"
            v-mask="'(##) ####-####'"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="mobileNumber"
            name="mobileNumber"
            label="DDD e Celular"
            dense
            outlined
            v-model="model.mobileNumber"
            v-mask="'(##) #####-####'"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6">
          <v-text-field
            id="phoneExtension"
            name="phoneExtension"
            label="Ramal"
            dense
            outlined
            v-model="model.phoneExtension"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-text-field
            id="email"
            name="email"
            label="Email"
            dense
            outlined
            :rules="[email]"
            v-model="model.email"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Dados bancários</h5>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-text-field
            id="bankAccountHolderName"
            name="bankAccountHolderName"
            label="Nome do titular"
            dense
            outlined
            :rules="checkBankRequired ? [required] : []"
            v-model="model.bankAccountHolderName"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <BaseBank
            id="bankId"
            name="bankId"
            label="Banco"
            clearable
            :bank="model.bank"
            :rules="checkBankRequired ? [required] : []"
            v-model="model.bankId"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6">
          <v-text-field
            id="bankBranchNumber"
            name="bankBranchNumber"
            label="Agência"
            maxlength="10"
            dense
            outlined
            :rules="checkBankRequired ? [required] : []"
            v-model="model.bankBranchNumber"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6">
          <v-text-field
            id="bankAccountNumber"
            name="bankAccountNumber"
            label="Conta"
            maxlength="10"
            dense
            outlined
            :rules="checkBankRequired ? [required] : []"
            v-model="model.bankAccountNumber"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="6">
          <v-text-field
            id="bankAccountDigit"
            name="bankAccountDigit"
            label="Digito"
            maxlength="1"
            dense
            outlined
            :rules="checkBankRequired ? [required] : []"
            v-model="model.bankAccountDigit"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-text-field
            id="bankAccountHolderDocument"
            name="bankAccountHolderDocument"
            label="CPF"
            dense
            outlined
            :rules="checkBankRequired ? [required, cpf] : []"
            v-mask="'###.###.###-##'"
            v-model="model.bankAccountHolderDocument"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-5" />

      <v-row dense>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <BaseButton
            id="btn-remove"
            name="btn-remove"
            outlined
            color="error"
            :title="labelBtnDelete"
            v-if="editMode"
            @click="deleteEmployee"
          />
          <BaseButton
            id="btn-reactivate"
            name="btn-reactivate"
            color="primary"
            outlined
            title="Reativar contratos"
            @click="reactivate"
            v-if="model.hasInactiveContracts"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="text-right">
          <BaseButton
            outlined
            id="btn-back"
            name="btn-back"
            color="primary"
            title="Voltar"
            @click="back"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
            v-if="canEdit"
          />
        </v-col>
      </v-row>
    </v-form>

    <DialogDelete
      v-if="dialog"
      title="Exclusão de titular"
      :dialog="dialog"
      :employeeId="model.id"
      :employeeName="model.name"
      @closeModal="closeModal"
    />

    <DialogReactivate
      v-if="dialogReactivate"
      :dialog="dialogReactivate"
      :employeeId="model.id"
      @closeModal="reactivate"
    />
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    DialogDelete: () => import('./dialogs/dialog-delete'),
    DialogDetachedContracts: () =>
      import('./dialogs/dialog-detached-contracts'),
    DialogReactivate: () => import('./dialogs/dialog-reactivate')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    editMode: {
      type: Boolean
    },
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    registeredStatus: null,
    valid: true,
    dialog: false,
    dialogReactivate: false,
    model: {
      id: null,
      customerId: null,
      customerName: null,
      name: null,
      document: null,
      gender: null,
      email: null,
      maritalStatus: null,
      mobileNumber: null,
      phoneNumber: null,
      phoneExtension: null,
      eventDate: null,
      birthDate: null,
      nationalIdCard: null,
      nationalIdCardDispatchDate: null,
      nationalIdCardIssuer: null,
      motherName: null,
      admissionDate: null,
      position: null,
      registration: null,
      pis: null,
      susCardNumber: null,
      zipCode: null,
      address: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      state: null,
      bank: {},
      bankId: null,
      bankAccountHolderName: null,
      bankBranchNumber: null,
      bankAccountNumber: null,
      bankAccountDigit: null,
      bankAccountHolderDocument: null,
      healthPlanId: null,
      dentalPlanId: null,
      lifeCoverageId: null,
      planCardNumber: null,
      situation: null,
      salary: null,
      dentalEffectiveStartDate: null,
      healthEffectiveStartDate: null,
      dentalPlanCardNumber: null,
      healthPlanCardNumber: null,
      hasDetachedContracts: false,
      lifeEffectiveStartDate: null,
      nationality: null,
      healthBillingDate: null,
      dentalBillingDate: null,
      lifeBillingDate: null,
      hasInactiveContracts: false
    }
  }),

  created() {
    this.search();
  },

  computed: {
    checkColumn() {
      return (
        this.model.maritalStatus === 'Casado(a)' ||
        this.model.maritalStatus === 'União estável'
      );
    },

    checkNationalIdRequired() {
      if (
        this.model.nationalIdCard ||
        this.model.nationalIdCardDispatchDate ||
        this.model.nationalIdCardIssuer
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkBankRequired() {
      if (
        this.model.bankId ||
        this.model.bankAccountHolderName ||
        this.model.bankBranchNumber ||
        this.model.bankAccountNumber ||
        this.model.bankAccountDigit ||
        this.model.bankAccountHolderDocument
      ) {
        return true;
      } else {
        return false;
      }
    },

    labelBtnDelete() {
      return this.model.situation !== 'Ativo' &&
        this.model.situation !== 'Em análise na operadora'
        ? 'Dados da exclusão'
        : 'Excluir';
    },

    canEdit() {
      const situation =
        this.model.situation === 'Ativo' ||
        this.model.situation === 'Em análise na operadora' ||
        this.model.situation === null;

      return situation;
    }
  },

  methods: {
    async search() {
      if (this.editMode) {
        try {
          const params = {
            customerId: this.customerId,
            employeeId: this.employeeId
          };

          const movementService = new MovementService();
          const { status, data } = await movementService.getEmployeeById(
            params
          );

          if (status === 200) {
            this.registeredStatus = data.situation;
            this.model = data;
            this.$store.commit('movements/setEmployee', data);
            this.$store.commit('movements/createTabs', { ...params, ...data });
          }
        } catch (error) {
          throw new Error(error);
        }
      } else {
        this.$store.commit('movements/createTabs', null);
      }
    },

    back() {
      this.$emit('back');
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = Object.assign({}, this.model);
      params.contracts = this.contracts;
      params.customerId = this.customerId;
      params.phoneNumber = unmask(params.phoneNumber);
      params.mobileNumber = unmask(params.mobileNumber);
      params.document = unmask(params.document);
      params.zipCode = unmask(params.zipCode);
      params.pis = unmask(params.pis);
      params.bankAccountHolderDocument = unmask(
        params.bankAccountHolderDocument
      );

      if (this.editMode) {
        this.handleUpdate(params);
      } else {
        this.handleCreate(params);
      }
    },

    async handleCreate(params) {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.create(params);

        if (status === 200) {
          const payload = {
            customerId: params.customerId,
            employeeId: data.employeeId
          };

          showMessage('success', 'Operação realizada com sucesso');
          this.redirect(payload);
          this.$store.commit('movements/createTabs', { ...params, ...data });
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async handleUpdate(params) {
      try {
        const movementService = new MovementService();
        const { status } = await movementService.update(params);

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    fill(params) {
      if (params) {
        this.model.address = params.logradouro;
        this.model.district = params.bairro;
        this.model.city = params.localidade;
        this.model.state = params.uf;
      }
    },

    deleteEmployee() {
      this.dialog = true;
    },

    closeModal(update) {
      this.dialog = false;

      if (update) {
        this.updatePage();
      }
    },

    redirect(payload) {
      this.$router.push({
        name: 'movimentacoes-new',
        params: { customerId: this.customerId, employeeId: payload.employeeId }
      });
    },

    updatePage() {
      this.$emit('updataInfo');
      this.search();
    },

    reactivate(search) {
      this.dialogReactivate = !this.dialogReactivate;

      if (search) {
        this.updatePage();
      }
    }
  }
};
</script>
